<template>
    <div class="video-translate-index-page">
        <div class="video-translate-wapper">
            <div class="video-translate-main">
                <div class="video-translate-content">
                    <div class="wapper-block">
                            <div class="bg-white radius-16" style="height: 253.29px;">
                                <div class="document-wrapper" v-if="docs.length > 0 && fileDuration <= limitDuration">
                                    <div class="close-btn" @click="closeUploadDocument">
                                        <svg-icon name="ib-close"></svg-icon>
                                    </div>
                                    <div class="file-type">
                                        <img width="32" src="../../../../static/images/tools/tools-video-icon.png" alt="video-icon">
                                    </div>
                                    <div class="margin-t-15 fonts-15 text-center text-omission" style="width: 90%;">{{docs[0].fileName}}</div>
                                    <div class="margin-t-10 margin-x-10 fonts-12 color-666666" v-if="fileDuration > 0">{{$t('tools.tools_videoTranslateIndex_page_tips_1', {duration: tools.msToTime(fileDuration)})}}</div>
                                    <div class="margin-t-15">
                                        <el-button round type="primary" size="medium" :loading="btnLoading" @click="createTask">{{$t('tools.tools_videoTranslateIndex_page_btn_1')}}</el-button>
                                    </div>
                                </div>
                                <template v-else>
                                    <div class="document-wrapper">
                                        <svg-icon name="ib-upload-o" style="font-size: 50px;"></svg-icon>
                                        <div class="text-center fonts-14 color-666666 margin-y-20 line-height-1.5">
                                            <div>{{$t('tools.tools_videoTranslateIndex_page_tips_2', {size: limitSize})}}</div>
                                            <!--<div>支持MP4格式，1分钟，100MB以内</div>  、AVI、FLV  ,.avi,.flv,.mov,.webm-->
                                            <!-- <div>建议上传单角色、单语种视频，人物在画面中比例尽量大，不要遮住脸部。</div> -->
                                            <div>{{$t('tools.tools_videoTranslateIndex_page_tips_3')}}</div>
                                        </div>
                                        <template v-if="limitSize > 0">
                                            <el-button type="primary" round @click="createDialogShow = true">{{$t('tools.tools_videoTranslateIndex_page_btn_2')}}</el-button>
                                            <!-- <upload-oss-button ref="uploadNode" title="上传视频" btn-size="medium" :size="100" accept=".mp4" @success="uploadFileSuccess"></upload-oss-button> -->
                                        </template>
                                    </div>
                                </template>
                            </div>
                    </div>
                    <!-- <div class="flex margin-t-20">
                        <div class="condition-item">
                            <div class="fonts-12 margin-b-10">源语言</div>
                            <el-select size="small" v-model="form.source" @change="langSelectChangeEvent" disabled placeholder="选择润色语言" style="width:100%">
                                <template v-for="item in languageList">
                                    <el-option :label="item.name" :value="item.key" :key="item.key"></el-option>
                                </template>
                            </el-select>
                        </div>
                        <div class="condition-item">
                            <div class="fonts-12 margin-b-10">目标语言</div>
                            <el-select size="small" v-model="form.target" @change="langSelectChangeEvent" disabled placeholder="选择润色语言" style="width:100%">
                                <template v-for="item in languageList">
                                    <el-option :label="item.name" :value="item.key" :key="item.key" :disabled="item.key === form.source"></el-option>
                                </template>
                            </el-select>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div style="width: 900px; margin: 10px auto;" v-if="list.length > 0">
            <!-- <el-button type="text" icon="el-icon-refresh-left" :loading="refreshLoading" @click="initTaskList(); queryLimitSize()">刷新列表</el-button> -->
            <el-button type="text" @click="setHistoryVisible">
                <template v-if="listVisible">
                    <svg-icon name="ib-d-arrow-up"></svg-icon> {{$t('tools.tools_videoTranslateIndex_page_btn_3')}}
                </template>
                <template v-else>
                    <svg-icon name="ib-d-arrow-down"></svg-icon> {{$t('tools.tools_videoTranslateIndex_page_btn_4')}}
                </template>
            </el-button>
        </div>
        <div class="video-translate-grid" v-if="listVisible">
            <template v-for="(item, index) in list">
                <div class="basic-card-item padding-0" @click="itemClikEvent(item)" :key="item.taskId">
                    <div class="poster">
                        <el-image fit="cover" :src="item.coverImage"></el-image>
                        <div class="play-cover" @click="playVideoEvent(item)" v-if="item.status === 'O'">
                            <svg-icon name="ib-play"></svg-icon>
                        </div>
                        <div class="play-cover" @click="playVideoEvent(item)" v-if="item.status === 'U'">
                            <div class="edit-button">{{$t('tools.tools_videoTranslateIndex_page_tips_4')}}</div>
                        </div>
                    </div>
                    <div class="padding-x-20 padding-y-16">
                        <el-tooltip :content="item.title" placement="top">
                            <div class="name">{{item.title}}</div>
                        </el-tooltip>
                        <div class="info margin-t-12">
                            <language-direction :source="item.sourceLang.key" :target="item.targetLang.key" type="half"></language-direction>
                            <el-divider direction="vertical" class="margin-x-8"></el-divider>
                            <div class="fonts-16">
                                <el-tooltip :content="$t('tools.tools_videoTranslateIndex_page_tips_5')" placement="top" v-if="item.lip">
                                    <svg-icon name="ib-lipsync-on"></svg-icon>
                                </el-tooltip>
                                <el-tooltip :content="$t('tools.tools_videoTranslateIndex_page_tips_6')" placement="top" v-else>
                                    <svg-icon name="ib-lipsync-off"></svg-icon>
                                </el-tooltip>
                            </div>
                            <el-divider direction="vertical" class="margin-x-8"></el-divider>
                            <div class="success" v-if="item.status === 'O'">{{$t('tools.tools_videoTranslateIndex_page_tips_7')}}</div> 
                            <div class="fail" v-else-if="item.status === 'F'">{{$t('tools.tools_videoTranslateIndex_page_tips_8')}}</div>
                            <div class="edit" v-else-if="item.status === 'U'">{{$t('tools.tools_videoTranslateIndex_page_tips_9')}}</div>
                            <div class="in-progress" v-else><i class="el-icon-loading"></i> {{item.statusDesc}}</div> 
                        </div>
                        <div class="flex flex flex-align-center margin-t-10">
                            <div class="time flex-1">{{item.createTime}}</div>
                            <template v-if="['O', 'F','U'].indexOf(item.status) > -1">
                                <div class="action" v-if="item.downloadLoading"><i class="el-icon-loading"></i></div>
                                <template v-else>
                                    <el-dropdown placement="bottom" @command="command => handleDropdownEvent(command, item, index)">
                                        <div class="action">
                                            <div class="default"><svg-icon name="ib-camera"></svg-icon></div>
                                            <div class="more"><svg-icon name="ib-more"></svg-icon></div>
                                        </div>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="download" v-if="item.status === 'O'">{{$t('tools.tools_videoTranslateIndex_page_tips_10')}}</el-dropdown-item>
                                            <el-dropdown-item command="delete" :divided="item.status === 'O'" style="color:#F56C6C;">{{$t('tools.tools_videoTranslateIndex_page_tips_11')}}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <template v-if="listVisible">
            <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
                <el-pagination 
                    background 
                    @current-change="handleCurrentChange"
                    layout="total, prev, pager, next"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>
            </div>
        </template>
        <el-dialog :title="$t('tools.tools_videoTranslateIndex_page_dialog_1_title')" :visible.sync="createDialogShow" destroy-on-close @open="openCreateDialog" @closed="closeCreateDialog" width="600px" top="20px">
            <div class="create-video-task-dialog">
                <div class="flex flex-justify-center flex-align-center" style="margin-bottom:15px;">
                    <type-radio :list="typeRadioOptions" :active="typeRadioSelected" @change="typeRadioChange" style=""></type-radio>
                    <el-tooltip placement="top" style="margin-left: -40px;">
                        <div slot="content" class="line-height-1.5" style="max-width:300px;">
                            {{$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_1')}}<span class="color-1AC49C">{{$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_2')}}</span>
                        </div>
                        <svg-icon name="ib-question" :className="typeRadioSelected === 'HUMAN_PROOFREADING' ? 'color-white' : ''"></svg-icon>
                    </el-tooltip>
                </div>
                <upload-oss :title="$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_3')" :multiple="false" ref="uploadOssDom" accept=".mp4" :size="userInfo.featureConfig.singleFileSize" :params="{}">
                    <div slot="subtitle" class="margin-t-10 color-919191 fonts-12">{{$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_4',{minute: limitDuration / 1000 / 60, size: userInfo.featureConfig.singleFileSize})}}</div>
                </upload-oss>
                <div class="flex margin-t-20">
                    <div class="flex-1">
                        <div class="fonts-14 margin-b-10">{{$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_5')}}</div>
                        <el-select v-model="form.source" @change="langSelectChangeEvent" :placeholder="$t('tools.tools_videoTranslateIndex_page_dialog_1_select_placeholder_1')" style="width:100%">
                            <template v-for="item in sourceLanguageOptions">
                                <el-option :label="item.name" :value="item.key" :key="item.key"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div class="flex-1 margin-l-20">
                        <div class="fonts-14 margin-b-10">{{$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_6')}}</div>
                        <el-select v-model="form.target" :placeholder="$t('tools.tools_videoTranslateIndex_page_dialog_1_select_placeholder_1')" style="width:100%">
                            <template v-for="item in targetLanguageOptions">
                                <el-option :label="item.name" :value="item.key" :key="item.key" :disabled="item.key === form.source"></el-option>
                            </template>
                        </el-select>
                    </div>
                </div>
                <div class="flex margin-t-20">
                    <div class="flex-1 flex flex-align-center">
                        <div class="fonts-14 margin-r-10">
                            <span>{{$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_7')}}</span>
                            <el-tooltip placement="top" class="margin-l-5">
                                <div slot="content" class="line-height-1.5" style="max-width: 300px;">
                                    {{$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_8')}}<span class="color-1AC49C">{{$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_9')}}</span>
                                </div>
                                <svg-icon name="ib-question" class="margin-l-5"></svg-icon>
                            </el-tooltip>
                        </div>
                        <div>
                            <next-switch v-model="form.lip" size="small"></next-switch>
                        </div>
                    </div>
                    <div class="flex-1 margin-l-20 flex flex-align-center">
                        <div class="fonts-14 margin-r-10"><span>{{$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_10')}}</span></div>
                        <div>
                            <next-switch v-model="form.subtitle" size="small"></next-switch>
                        </div>
                    </div>
                </div>
                <div class="flex margin-t-20" v-if="showSelectEngine">
                    <div class="flex-1 flex flex-align-center">
                        <div class="fonts-14 margin-r-10">{{$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_11')}}</div>
                        <div class="flex-1">
                            <el-select v-model="form.engine">
                                <template v-for="(item, index) in engineList">
                                    <el-option :value="item" :label="item" :key="index"></el-option>
                                </template>
                            </el-select>
                        </div>
                    </div>
                    <div class="flex-1"></div>
                </div>
                <div class="text-center margin-t-20">
                    <span>
                        {{$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_12')}}<span :class="spendPoints <= limitSize ? `color-1AC49C` : `color-DE0000`">{{spendPoints}}</span>
                    </span>
                    <span class="margin-l-20">{{$t('tools.tools_videoTranslateIndex_page_dialog_1_tips_13', {size: limitSize})}}</span>
                </div>
            </div>
            <div slot="footer" class="text-center">
                <el-button plain round size="small" @click="createDialogShow = false">{{$t('tools.tools_videoTranslateIndex_page_dialog_1_btn_1')}}</el-button>
                <el-button type="primary" round size="small" :loading="btnLoading" @click="createTask">{{$t('tools.tools_videoTranslateIndex_page_dialog_1_btn_2')}}</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="previewDialog.param.title" :visible.sync="previewDialog.show" destroy-on-close @opened="openedPreviewDialog" @closed="closePreviewDialog" class="none-padding-body" width="960px" top="20px">
            <div>
                <video ref="previewVideo" :poster="previewDialog.param.coverImage" :src="previewDialog.param.translatedVideo" controls controlsList="nodownload" width="100%" height="540px"></video>
            </div>
            <div slot="footer" class="text-center">
                <el-button type="primary" round :loading="previewDialog.loading" @click="downloadVideo(previewDialog.param, -1)">{{$t('tools.tools_videoTranslateIndex_page_dialog_2_btn_1')}}</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="alertDialogDom" :message="alertInfo.message" :type="alertInfo.type" :buttonType="alertInfo.buttonType" :cancelButtonName="alertInfo.cancelButtonName" @confirmEvent="alertInfo.confirmEvent"></alert-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import tools from '@/utils/tools';
import config from '@/utils/config';
import { toolsApi, docApi } from '@/utils/api';
import UploadOss from '@/components/UploadOss';
import UploadOssButton from '@/components/UploadOssButton';
import AlertDialog from '@/components/AlertDialog';
import languageDirection from '@/components/LanguageDirection';
import NextSwitch from '@/components/NextSwitch';
import TypeRadio from '@/components/TypeRadio';
export default {
    components: { UploadOss, UploadOssButton, AlertDialog, languageDirection, NextSwitch, TypeRadio, },
    data (){
        return {
            config,
            tools,
            maxlength: 2000,
            form: {
                source: 'zh',
                target: 'en',
                lip: false,
                subtitle: false,
                engine: 'default',
            },
            listVisible: true,
            refreshLoading: false,
            list: [],
            total: 0,
            pageNumber: 1,
            pageSize: 6,
            taskTimer: null,
            sizeTimer: null,
            docs: [],
            fileType: '',
            fileDuration: -1,
            limitDuration: 5 * 60 *1000, // TODO 2 改成 5
            limitFileSize: 500, // TODO 100 改成 500
            limitSize: 0,
            spendPoints: 1,
            btnLoading: false,
            docTaskData: 0,
            taskId: null,
            createDialogShow: false,
            previewDialog: {
                show: false,
                loading: false,
                param: {},
            },
            alertInfo: {
                message: '',
                type: 'confirm',
                cancelButtonName: this.$t('tools.tools_videoTranslateIndex_page_data_alertInfo_cancelButtonName'),
                buttonType: 'primary',
                confirmEvent: ()=>{},
            },
            sourceLanguageOptions: [],
            targetLanguageOptions: [],
            typeRadioOptions:[
                {key: 'ONE_CLICK', name: this.$t('tools.tools_videoTranslateIndex_page_data_typeRadioOptions_name_1')},
                {key: 'HUMAN_PROOFREADING', name: this.$t('tools.tools_videoTranslateIndex_page_data_typeRadioOptions_name_2')}
            ],
            typeRadioSelected:'ONE_CLICK',
            showSelectEngine: false,
            engineList:['default', 'test'],
            
        }
    },
    computed: {
        ...mapState({
            // textTypeOptions: state => state.common.polishTextTypes,
            // styleOptions: state => state.common.polishStyleOptions,
            userInfo: state => state.user.userData,
        }),
    },
    created(){
        this.initLanguageOptions();
    },
    methods: {
        initTaskList(){
            let url = `${toolsApi.queryVideoTranslateTasks}?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`;
            this.refreshLoading = true;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.list = res.data.records;
                    this.total = res.data.total;
                }
            }).finally(()=>{
                this.refreshLoading = false;
            })
        },
        queryLimitSize() {
            let url = `${toolsApi.queryVTLimitSize}?toolType=VT`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.limitSize = res.data.balance;
                }
            });
        },
        initLanguageOptions(){
            let url = toolsApi.queryVideoLanguageOptions;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.sourceLanguageOptions = res.data.sourceLanguages;
                    this.targetLanguageOptions = res.data.targetLanguages;
                }
            });
        },
        handleCurrentChange(val){
            this.pageNumber = val;
            this.initTaskList();
            this.queryLimitSize();
        },
        createTask() {
            this.docs = this.$refs.uploadOssDom.getOssFiles();
            if(this.docs.length === 0){
                this.$message.error(this.$t('tools.tools_videoTranslateIndex_page_method_createTask_message_1'));
                return;
            }
            let url = toolsApi.startVideoTranslateTask;
            let postData = {
                sourceLanguage: this.form.source,
                targetLanguage: this.form.target,
                lip: this.form.lip,
                taskType: this.typeRadioSelected,
                subtitleAfterTrans: this.form.subtitle,
                engine: this.showSelectEngine ? this.form.engine : 'default',
                title: this.docs[0].fileName,
                path: this.docs[0].objectName,

            };
            // console.log(postData);
            // return
            this.btnLoading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200){
                    this.initTaskList();
                    this.closeUploadDocument();
                }
            }).finally(()=>{
                this.createDialogShow = false;
                this.btnLoading = false;
                this.queryLimitSize();
            });

        },
        playVideoEvent(param) {
            if(param.status === 'U'){
                this.$router.push({
                    path: '/tools/videoTranslate/editer',
                    query: {
                        id: param.taskId,
                    }
                });
            }else if(param.status === 'O'){
                this.previewDialog.param = param;
                this.previewDialog.show = true;
            }
        },
        itemClikEvent(param) {
            if(param.status === 'U'){
                this.$router.push({
                    path: '/tools/videoTranslate/editer',
                    query: {
                        id: param.taskId,
                    }
                });
            }
        },
        openedPreviewDialog() {
            this.$refs.previewVideo.play();
        },
        closePreviewDialog(){
            this.$refs.previewVideo.pause();
        },
       
        langSelectChangeEvent(val){
            if(val === this.form.target) {
                if(val === 'zh'){
                    this.form.target = 'en';
                }else {
                    this.form.target = 'zh';
                }
            }
        },
        async uploadFileSuccess() {
            // 文件上传成功
            let file = this.$refs.uploadNode.getFiles().list[0];
            this.docs = this.$refs.uploadNode.getOssFiles();
            let fileName = this.docs[0].fileName.toLocaleLowerCase();
            this.fileType = fileName.split(".").pop();
            try {
                this.fileDuration = await tools.getMediaFileTime(file.raw);
            } catch (error) {
                this.fileDuration = -1;
            }
            console.log('[视频时长]', this.fileDuration);
            if(this.fileDuration > this.limitDuration){
                this.$message.warning(this.$t('tools.tools_videoTranslateIndex_page_method_uploadFileSuccess_message_1'));
            }
            // console.log(this.docs);
        },
        
        closeUploadDocument() {
            this.docs = [];
        },
        handleDropdownEvent(command, item, index) {
            switch (command) {
                case 'download':
                    this.downloadVideo(item, index);
                    break;
                case 'delete':
                    this.deleteTask(item);
                    break;
            }
        },
        async downloadVideo(item, index) {
            if(index === -1){
                this.previewDialog.loading = true;
            }else {
                this.list.splice(index, 1, {...item, downloadLoading: true})
            }
            const a = document.createElement("a");
            a.href = item.translatedVideo.replace('http://', 'https://');
            a.download = item.title;
            a.click();
            a.remove();
            await tools.sleep(1000);
            if(index === -1){
                this.previewDialog.loading = false;
            }else {
                this.list.splice(index, 1, {...item, downloadLoading: false})
            }
        },
        deleteTask(item){
            this.taskId = item.taskId;
            this.showAlertDialog({
                message: this.$t('tools.tools_videoTranslateIndex_page_method_deleteTask_message_1'),
                buttonType: 'danger',
            }, ()=>(this.deleteTaskEvent()));
        },
        deleteTaskEvent(){
            let url = toolsApi.deleteVideoTranslateTask;
            this.$ajax.post(url, {taskIdList: [this.taskId]}).then(res => {
                if(res.status === 200){
                    this.initTaskList();
                    this.$message.success(this.$t('tools.tools_videoTranslateIndex_page_method_deleteTaskEvent_message_1'));
                    this.queryLimitSize();
                }
            });
        },
        showAlertDialog(params, confirmEvent) {
            this.alertInfo = {...this.alertInfo, ...params, confirmEvent};
            this.$refs.alertDialogDom.setShow(true);
        },
        setHistoryVisible() {
            this.listVisible = !this.listVisible;
            if (this.listVisible) {
                this.initTaskList();
            }
            this.queryLimitSize();
        },
        openCreateDialog() {

        },
        closeCreateDialog() {
            this.form = this.$options.data().form;
            this.docs = [];
        },
        pollingTaskStatus() {
            let ids = [];
            this.list.map(item => {
                // item.status !== "O" && item.status !== "F" && item.status !== "U"
                if (!['O', 'F', 'U'].includes(item.status)){
                    ids.push(item.taskId);
                }
            });
            if (ids.length > 0) {
                let url = toolsApi.startVideoTranslateTasksStatus;
                this.$ajax.post(url, {taskIds: ids}).then(res => {
                    if (res.status === 200) {
                        let statusData = {};
                        res.data.map(item=>{
                            statusData[item.taskId] = item;
                        });
                        this.list.map((item, index) => {
                            if(statusData[item.taskId]){
                                this.list.splice(index, 1, {...item, ...statusData[item.taskId]})
                            }
                        });
                    }
                });
            }
        },
        typeRadioChange(item){
            this.typeRadioSelected = item.key;
        },
        getCurrentSpendPoints() {
            let point = 1;
            if(this.typeRadioSelected === 'HUMAN_PROOFREADING') {
                point += 1;
            }
            if(this.form.lip) {
                point += 1;
            }
            this.spendPoints = point;

            if(this.typeRadioSelected === 'ONE_CLICK' && this.form.source === 'zh' && this.form.target === 'en' && this.form.lip === true && this.form.subtitle === false) {
                this.showSelectEngine = true;
            }else {
                this.showSelectEngine = false;
            }
        },

    },
    mounted(){
        this.initTaskList();
        this.taskTimer = setInterval(() => {
            this.pollingTaskStatus();
        }, 1000 * 10);
        this.queryLimitSize();
        this.sizeTimer = setInterval(() => {
            this.queryLimitSize();
        }, 1000 * 60 * 5);
    },
    watch: {
        typeRadioSelected(newVal, oldVal) {
            this.getCurrentSpendPoints();
        },
        form: {
             deep:true,
             immediate: true,
             handler(newVal, oldVal) {
                this.getCurrentSpendPoints();
             }
        }
    },
    beforeDestroy(){
        clearInterval(this.taskTimer);
        this.taskTimer = null;
        clearInterval(this.sizeTimer);
        this.sizeTimer = null;
    }
}
</script>
<style lang="scss" scoped>
.video-translate-index-page {
    position: relative;
    height: 100%;
    background-image: url("../../../../static/images/tools/video-translate-bg.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto;
    .video-translate-wapper {
        // min-height: 1000px;
        width: 900px;
        margin: 20px auto 0px auto;
        background: rgba(255, 255, 255, 0.5);
        border: 0.669748px solid rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(20.0924px);
        border-radius: 20px;
    }
    .video-translate-grid {
        width: 900px;
        margin: 0px auto;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        .poster {
            height: 124px;
            position: relative;
            .play-cover {
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: #ffffff;
                font-size: 30px;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 10px 10px 0 0;
                display: none;
            }
            .edit-button {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: rgba(128, 128, 128, 0.8); // 灰色半透明背景
                color: white;
                padding: 8px 16px;
                border-radius: 20px; // 圆角
                font-size: 14px;
                cursor: pointer;
                transition: background-color 0.3s;
                
                &:hover {
                    background-color: rgba(128, 128, 128, 1); // 悬停时加深颜色
                }
            }
            &:hover {
                .play-cover {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            ::v-deep .el-image{
                width: 100%;
                height: 100%;
                border-radius: 10px 10px 0px 0px;
            }
        }
        .in-progress {
            color: #2979FF;
        }
        .fail {
            color: #F56C6C;
        }
        .success {
            color: #00B670;
        }
        .edit {
            color: #FFA800;
        }
    }
    .video-translate-main{
        // min-height: 900px;
        width: 860px;
        margin: 20px auto;
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid #FFFFFF;
        box-shadow: 0px 20px 100px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        border-radius: 20px;
        .title-content{
            height: 52px;
            box-sizing: border-box;
            border-bottom: 1px solid #0000001A;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-weight: 600;
            img{
                width: 33px;
                height: 32px;
                margin-right: 8px;
            }
        }
        .video-translate-content {
            padding: 20px;
            ::v-deep .el-textarea {
                &.is-disabled .el-textarea__inner {
                    background-color: #ffffff;
                }
            }
            .condition-item {
                width: 120px;
                padding-right: 10px;
            }
            
            .condition-custom {
                ::v-deep .el-input {
                    .el-input__inner{
                        // height: 24px;
                        // font-size: 12px;
                        border-radius: 20px;
                        border-style: dashed;
                        padding-right: 45px;
                    }
                }
            }
            .model-wapper {
                margin-right: 10px;
                .model-list {
                    display: flex;
                    .item {
                        height: 26px;
                        margin-left: 10px;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 15px;
                        background-color: #ffffff;
                        border-radius: 4px;
                        box-sizing: border-box;
                        border: 1px solid #0000001A;
                        cursor: pointer;
                        &:first-child {
                            margin-left: 0;
                        }
                        &.active {
                            border-color: #1AC49C;
                            cursor: default;
                        }
                    }
                }
            }
            .document-wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                .file-type {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background-color: #F8F8F8;
                    font-size: 33px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .polish-status {
                    height: 24px;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    background-color: #2979FF33;
                    color: #2979FF;
                    border-radius: 20px;
                    &.warning {
                        background-color: #FF990033;
                        color: #FF9900;
                    }
                    &.error {
                        background-color: #F56C6C33;
                        color: #F56C6C;
                    }
                    &.success {
                        background-color: #00B67033;
                        color: #00B670;
                    }
                    i{
                        margin-right: 4px;
                    }
                }
                .close-btn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #666666;
                    padding: 14px;
                    cursor: pointer;
                    &:hover {
                        color: #000000;
                    }
                }
            }
        }
        .result-title-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 52px;
            .left-content{
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 15px;
                img{
                    width: 33px;
                    height: 32px;
                    margin-right: 12px;
                }
            }
        }
        .result-content {
            margin-top: 10px;
            font-size: 14px;
            line-height: 22px;
            height: 162px;
            // max-height: 240px;
            overflow-y: auto;
            ::v-deep .diff-highlight {
                background-color: #D4E4FF;
                // font-weight: 600;
            }
            
        }
    }
    .wapper-block {
        background: linear-gradient(180deg, rgba(215, 245, 232, 0.6) 0%, rgba(215, 233, 245, 0) 100%);
        backdrop-filter: blur(20.0924px);
        border-radius: 20px;
        padding: 15px;
        
    }
    .style-label {
        padding: 0 10px;
        height: 32px;
        font-size: 13px;
        background-color: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;
        &:first-child{
            margin-left: 0;
        }
        &:hover{
            .name{
                color:#000;
            }
        }
        
        &.active {
            border-color: #00B670;
            .name{
                color: #00B670;
            }
        }
        .name {
            color: #999999;
        }
        .close {
            color: #999999;
            margin-left: 10px;
            font-size: 14px;
            &:hover{
                color: #000;
            }
        }
    }
    .text-input {
        margin-top: 10px;
        ::v-deep .el-textarea__inner{
            padding: 0;
            border: none;
        }
    }
    .copy-btn {
        width: 60px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        border-radius: 32px;
        font-size: 12px;
        cursor: pointer;
    }
    [disabled],:disabled{
        cursor: not-allowed;
        pointer-events: none;
        opacity: .5;
    }
    .send-btn {
        font-size: 20px;
        padding: 5px;
    }
}
.create-video-task-dialog {
    ::v-deep .type-radio-component{
        .item {
            &:last-child {
                padding-right: 30px;
            }
        }
    }
}
</style>
